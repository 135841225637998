import React, { useState, useEffect } from 'react'
import CopperService from '../../services/api/copper';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';
import moment from 'moment';

const CopperHistory = ({ shift_date, shift_id }) => {
    const [data, setData] = useState([])
    moment().locale('mn')
    useEffect(() => {
        fetchData()
    }, [shift_date, shift_id])
    const fetchData = async () => {
        try {
            const result = await CopperService.getSentDate(shift_date, shift_id, 2)
            setData(result.data)
        } catch (err) {
            console.log('err');
        }
    }
    const onCellPrepared = (e) => {
        let elem = e.cellElement.style
        elem.fontSize = 14
        elem.fontFamily = "Segoe UI"
        if (e.rowType === 'header') {
            e.cellElement.setAttribute('style', 'font-weight: bold; height: 40px; color: black; background-color: #e9ecef; text-align: center; vertical-align: middle')
        }
        else if (e.rowType === "data") {
            e.cellElement.setAttribute('style', 'color: black; vertical-align: middle; text-align:center;')
        }
    }
    return (
        <div style={{ padding: 10 }}>
            <b>БҮРТГЭЛИЙН ТҮҮХ</b>
            <DataGrid
                onCellPrepared={onCellPrepared}
                showBorders
                showColumnLines
                showRowLines
                dataSource={data}
                style={{ marginTop: 10 }}
                noDataText='Мэдээлэл байхгүй байна'
            >
                <Paging enabled={true} defaultPageSize={5}/>
                <Column
                    caption="№"
                    width='3rem'
                    alignment='center'
                    cellRender={(data) => {
                        const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
                        return <span>{adjustedIndex}</span>;
                    }}
                />
                <Column dataField='createdAt' caption='Шинэчлэгдсэн огноо' customizeText={(e) => {
                    return moment(e.value).format('YYYY-MM-DD HH:mm:ss')

                }} />
                <Column dataField='status' caption='Төлөв' alignment='center' cellRender={(data) => {
                const text = data.value
                let bgColor;
                let color;
                let brColor;
                switch (text) {
                  case 'Баталсан':
                    bgColor = ' #E5FEF7'
                    color = '#40B6AF'
                    brColor = '#40B6AF'
                    break
                  case 'Татгалзсан':
                    bgColor = '#FFEFED'
                    color = '#F95F53'
                    brColor = '#F95F53'
                    break
                  case 'Илгээсэн':
                    bgColor = '#CDEBFF'
                    color = '#000'
                    brColor = '#CDEBFF'
                    break
                  case 'Шинэ':
                    bgColor = '#FFEEDF'
                    color = '#F99D4B'
                    break
                  default:
                    bgColor = ''
                    break
                }
                return (
                  <div style={{ backgroundColor: bgColor, color: color, borderRadius: 20, border: `.5px solid ${brColor}`, margin: 'auto', fontWeight: 'bold', width: 100, padding: '5px 20px'}}>{data.text}</div>
                )
              }}/>
                <Column dataField='full_name' caption='Овог нэр' />
                <Column dataField='department' caption='Алба хэлтэс/Албан тушаал' />

            </DataGrid>
        </div>
    )
}

export default CopperHistory