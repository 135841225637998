import request from '../../shared/lib/request'

const fileUpload = (copperBundleId, typeId, body) => {
    return request({
        url:`/fileServices/uploadFileBundle?copperBundleId=${copperBundleId}&typeId=${typeId}`,
        method:'POST',
        data: body
    })
}
const deleteFile = (file_name) => {
    return request({
        url:`/fileServices/deleteFile/${file_name}`,
        method:'DELETE',
    })
}

const FileServices = {
    fileUpload,
    deleteFile
}

export default FileServices;